import { useEffect, useRef, useState } from 'react';

const findBreakPoint = (breakpoints, width) => {
  const breakpointKey = Object.keys(breakpoints).find(
    size => parseFloat(breakpoints[size]) > width
  );

  return breakpointKey;
};

const useResponsiveBreakpoints = (elementRef, breakpoints) => {
  const smallestBreakpoint = Object.keys(breakpoints)[0];
  const [breakSize, setBreakSize] = useState(smallestBreakpoint);
  let breakRef = '';

  if (typeof window !== 'undefined') {
    breakRef = new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      setBreakSize(findBreakPoint(breakpoints, width));
    });
  }

  const observer = useRef(breakRef);

  useEffect(() => {
    const currentObserver = observer.current;
    const currentElement = elementRef.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      currentObserver.unobserve(currentElement);
    };
  }, [elementRef, observer]);

  return breakSize;
};

export default useResponsiveBreakpoints;
